.about-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    padding: 25px;
    width: 95%;
    height: 95%;
    z-index: 20;
    margin: auto;
    overflow-y: scroll;
}
.about-popup.active {
    display: block;
}
.about-popup-content {
    max-width: 760px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}
.popup-header {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    margin-bottom: 45px;
}
.popup-title {
    font-size: 80px;
    color: #fff;
    -webkit-text-stroke: 2px var(--main-color);
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 10px;
}
.popup-subtitle {
    font-size: 24px;
    letter-spacing: 1.5px;
    color: var(--main-color);
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px;
    max-width: 640px;
}
.popup-text {
    font-size: 18px;
    text-align: center;
    color: var(--main-color);
}
.bold-text {
    font-weight: bold;
    margin: 30px 0;
}
.popup-close {
    position: fixed;
    top: 3.5%;
    right: 3%;
    width: 42px;
    height: 42px;
    transform: rotate(45deg);
}
.disabled-button {
    display: block;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    background-color: #88cfbe;
    font-size: 18px;
    font-weight: 300;
    color: #ded9d9;
    text-transform: uppercase;
}

/* .screenshot_item_wrapper{
  transition: all 0.1s ease-in;
}

.screenshot_item_wrapper_remove_animation{
  animation: fallAway2 forwards 0.9s ease-in-out 1;
}
.screenshot_item_wrapper_animation{
  animation: fallAway forwards 0.8s ease-in-out 1;
}


@keyframes fallAway2 {
  0% {
    scale: 1;
  }

  100% {
    scale: 0;
    position: absolute;
  }
}


@keyframes fallAway {
  0% {
    scale: 1;
    top: 0;
    opacity: 1;
    translate: 0%;
  }

  25% {
    scale: 0.5;
  }

  99% {
    translate: calc(400% + 80px) calc(-100% - 20px);
  }

  100% {
    scale: 0;
    top: -300px;
    opacity: 0;
    display: none;
    translate: 0 0;
  }
}


@keyframes leftTransition {
  0% {
    translate: 0%;
  }

  99% {
    translate: calc(-100% - 20px);
  }

  100% {
    translate: 0 0;
  }
}

@keyframes rightTransition {
  0% {
    translate: 0 0;
  }

  99% {
    translate: calc(400% + 80px) calc(-100% - 20px);
  }

  100% {
    translate: 0 0;
  }
} */


article.custom-card-1__card-body.card-body.screenshot_item_wrapper_remove_animation~article.custom-card-1__card-body.card-body {
animation: leftTransition forwards 0.7s ease-in-out 1;
}

article.custom-card-1__card-body.card-body.screenshot_item_wrapper_remove_animation~article.custom-card-1__card-body.card-body:nth-child(6) {
  animation: rightTransition forwards 0.7s ease-in-out 1;
}

article.custom-card-1__card-body.card-body.screenshot_item_wrapper_remove_animation {
  animation: fallAway forwards 0.8s ease-in-out 1;
}
.presentation__img {
  width: 35%;
  height: auto;
  position: relative;
  border-radius: 10px;
  background-repeat: no-repeat;
}
.number__circle {
  position: absolute;
  right: 3px;
  top: 16px;
  background-color: white;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.presentation__container {
  display: flex;
  flex-direction: row;
  /*margin-top: 25px;*/
  width: 100%;
  height: 100%;
  padding: 10px 0 0 0 ;
}
.card__body {
  height: auto;
}
.presentation__container p{
  font-size: 12px;
  margin-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
  width: 65%;
  height: 90%;
  align-self: center;
}
.presentation-card:not(.hasLink){
  opacity: 0.8;
}

.card_action_section {
  display: flex;
  justify-content: center;
}

.is_owner_icon {
  margin-right: 8px;
  aspect-ratio: 1 / 1;
}

@keyframes fallAway {
  0% {
    scale: 1;
    top: 0;
    opacity: 1;
  }

  25% {
    scale: 0.5;
  }

  100% {
    scale: 0;
    top: -300px;
    opacity: 0;
  }
}

@keyframes leftTransition {
  0% {
    translate: 0%;
  }

  99% {
    translate: calc(-100% - 20px);
  }

  100% {
    translate: 0 0;
  }
}

@keyframes rightTransition {
  0% {
    translate: 0 0;
  }

  99% {
    translate: calc(400% + 80px) calc(-100% - 20px);
  }

  100% {
    translate: 0 0;
  }
}

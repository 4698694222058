.slide__background {
    width: calc(32% - 3px);
    margin-bottom: 10px;
    margin-right: 1.8%;
    cursor: pointer;
    padding: 5px;
    height: 11.5vw;
    /*box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 0 0 1px #EAEAEA;*/
}
.slide__background p{
    color: #6E6B7B;
    font-family: Mardoto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
}
.active_card {
    border-radius: 10px;
    background: rgba(36, 178, 99, 0.12);
}

.slide__background:nth-child(3n) {
    margin-right: 0;
}

.slide__img {
    display: flex;
    width: 100%;
    height: 10vw;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    background-size: cover;
    justify-content: center;
}

.slide__img p {
    background: rgba(255, 255, 255, 0.35);
    color: #2C476C;
    font-family: Mardoto;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    width: 45px;
    height: 45px;
    text-align: center;
    align-self: center;
    border-radius: 50px;
}

.dynamic-share-btn{
    border: 1px solid #2C476C;
    filter: drop-shadow(0px 7px 26px rgba(44, 71, 108, 0.1));
    border-radius: 20px;
    padding: 1px 10px;
    height: max(20px,calc(100vh/107));
    margin: 3px 0px;

    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 500;
    font-size: max(9px,calc(100vh/242));;
    /* identical to box height */
    text-transform: capitalize;
    color: #2C476C;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .dynamic-share-btn{
        height: max(17px,calc(100vh/107));
        font-size: max(9px,calc(100vh/215));
    }
    .dynamic-screen-shot-share-content__header h4{
        font-size: 16px;
    }
}
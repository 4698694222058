.card-body__date-section-info.date-section-info{
    display: grid;
    width: 100%;
    grid-template-columns: calc(100% - 80px) 75px;
    gap: 5px;
    align-items: flex-end;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .card-body__date-section-info.date-section-info{
        grid-template-columns: calc(100% - 64px) 60px;
    }
}

.card-body__presentation-edit-content.dynamic-screen-shot-edit-content {
    background: #F5F5F5;
    border-radius: 11px;
    width: 650px;
    height: 500px;
}
.card-body__dynamic-close-btn-box{
    height: 20px;
}
.card-body__dynamic-close-btn-box svg{
    height: 100%;
    width: 100%;
}
.dynamic-screen-shot-edit-content__header h4{
    font-size: 16px;
}

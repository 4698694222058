.card-body__dynamic-actions.dynamic-actions{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #2C476C;
    border-radius: 6px;
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-size: max(13px,calc(100vh/215));
    line-height: 10px;
    font-weight: 400;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 8px 12px;
    gap: 3px;
}
.card-body__dynamic-actions.dynamic-actions h6{
    cursor: pointer;
    font-weight: 300;
    font-size: max(13px,calc(100vh/215));
    line-height: 1;
}

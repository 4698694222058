.card-body__edit-screenshot-title-form.edit-screenshot-title-form {
    margin: 15px 0;
    display: grid;
    gap: 20px;
}
.card-body__edit-screenshot-title-form.edit-screenshot-title-form button{
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
    width: 120px;
}
.edit-screenshot-title-form__btn-box{
    position: relative;
}

.edit-screenshot-title-form__btn-box>div{
    width: 100%;
    padding: 0;
    border-radius: 6px;
}
.edit-screenshot-description-form__btn-box{
    width: 100%;
    height: 180px;
    border-radius: 6px;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 5px;
    cursor: text;
}
.edit-screenshot-description-form__btn-box::-webkit-scrollbar-thumb {
    background-color: transparent;
}
.edit-screenshot-description-form__btn-box::-webkit-scrollbar {
    width: 12px;
}
.edit__description__area {
    width: 100%;
    min-height: 160px;
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    border: none;
    color: #2C476C;
    resize: none;
    background: #F5F5F5;
}

.edit-screenshot-description-form__btn-box:hover {
    border: 1px solid #000;
}

.edit__area__focused {
    width: 100%;
    height: 180px;
    border-radius: 6px;
    overflow: auto;
    cursor: text;
    border: 2px solid #1976d2;
    padding: 4px;
}

.card-body__edit-screenshot-title-form.edit-screenshot-title-form button{
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
}

@media screen and (min-width: 900px) and (max-width: 1800px) {
    .card-body__edit-screenshot-title-form.edit-screenshot-title-form h5{
       font-size: 13px;
    }
}

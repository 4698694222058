.card-body__dynamic-actions-btn-box.dynamic-actions-btn-box{
    display: flex;
    justify-content: space-between;
}

.card-body__dynamic-actions-btn-box.dynamic-actions-btn-box .dynamic-action-btn svg{
   height: max(20px,calc(100vh/138));
    transition: 0.1s;
}
.card-body__dynamic-actions-btn-box.dynamic-actions-btn-box .dynamic-action-btn[data-active='true'] svg{
    fill: #2C476C;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .card-body__dynamic-actions-btn-box.dynamic-actions-btn-box .dynamic-action-btn svg{
        height: max(16px,calc(100vh/138));
    }
}

.slides__show__container {
    position: fixed;
    border-radius: 12px;
    background: #FFF;
    width: 400px;
    left: 115px;
    top: max(calc(100vh/216.2),50px);
    display: flex;
    align-self: flex-start;
    padding: 5px 8px;
    display: flex;
    flex-direction: column;
}

.slides__show__container button {
    color: #24B263;
    font-family: Mardoto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-decoration-line: underline;
    padding: 10px 0;
}

.main__left.left-side .legend .main__subitem-label {
    left: 400px;
}
.main__left.left-side  .legend .main__subitem-text-label  {
    display: flex;
    width: 34px;
    height: 34px;
    z-index: 2;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 366px;
}
.main__left.left-side  .legend .main__subitem-text-label  input{
    position: absolute;
    visibility: hidden;
}
.main__left.left-side .Collapsible{
    margin-left: 10px;
    padding: 10px 5px 10px 10px;
    cursor: pointer;
    position: relative;
}
.main__left.left-side .Collapsible__trigger{
    padding-left: 10px  ;
    opacity: 1  ;
    color: rgba(44, 71, 108, 1)  ;
    font-family: "NotoSansArmenian-Bold"  ;
    font-size: 20px  ;
    font-weight: 700  ;
    font-style: normal  ;
    letter-spacing: 1.24px  ;
    text-align: left  ;
    text-transform: uppercase  ;
    display: flex;
    align-items: center;
    position: relative;
}
.main__left.left-side  .Collapsible__trigger span:nth-child(1){
    width: 85%;
    /*text-wrap: nowrap;*/
    /*text-overflow: ellipsis;*/
    /*overflow: hidden;*/
}
.main__left.left-side  .Collapsible__trigger .collapseIcon{
    position: absolute;
    display: flex;
    z-index: 1;
    cursor: pointer;
    left: 325px;
}

.main__left.left-side  .Collapsible__trigger .collapseIcon:after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    transition: .5s;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cg id='dropdown-blue'%3E%3Cg id='vuesax/linear/arrow-down'%3E%3Cg id='vuesax/linear/arrow-down_2'%3E%3Cg id='arrow-down'%3E%3Cpath id='Vector' d='M14.9401 6.71246L10.0501 11.6025C9.47256 12.18 8.52756 12.18 7.95006 11.6025L3.06006 6.71246' stroke='%23292D32' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    rotate: 180deg;
}
.main__left.left-side .Collapsible .Collapsible__contentOuter{
    position: relative;
}
.main__left.left-side  .Collapsible .main__subitem-label {
    left: 383px;
}
.main__left.left-side  .Collapsible .main__subitem-label.item {
    left: 357px;
}
.main__left.left-side  .Collapsible .main__subitem-label.item svg {
    width: 20px;
}

.main__left.left-side   .main__subitem-text-label  svg{
    width: 20px;
}
.main__left.left-side  .Collapsible__trigger.is-open .collapseIcon:after {
    rotate: 0deg;
}
.main__left.left-side .Collapsible__contentInner .main__subitem-li{
 position: unset;
}

.main__left.left-side .Collapsible .legend-layar-eye{
    top: 50%;
    left: 50%;
    translate: -10% -50%;
}
.main__left.left-side .Collapsible .legend-layar-text{
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -104% -50%;
}
.main__left.left-side .legend-icon svg *, .main__left.left-side .legend.active .main__subitem-heading svg *{
    stroke: var(--main-color);
}
.main__left.left-side .legend.active .legend-icon svg *, .main__left.left-side .legend.active .main__subitem-heading svg *{
    stroke: white;
}

.main__subitem-list > .main__subitem-li > .main__subitem-label.clock-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 512px) and (max-width: 1024px) {
    .main__left.left-side .Collapsible .legend-layar-text  {
        left: 77.5%;
    }
    .main__left.left-side  .Collapsible .main__subitem-label.item {
        left: 254px;
    }
    .main__left.left-side .legend .main__subitem-heading>.main__subitem-text-label{
        left: 255px
    }
    .main__left.left-side .Collapsible .legend-layar-eye{
        left: 65%;
    }
    .main__left.left-side .Collapsible__contentOuter .main__subitem-label {
        left: 270.5px;
    }
    .main__left.left-side .legend .main__subitem-heading>.main__subitem-label{
        left: 280.5px;
    }
    .main__left.left-side .Collapsible__trigger .collapseIcon{
        left: 240px;
    }
}

@media (max-width: 1024px) {

    .main__subitem-list.person > .main__subitem-li > .main__subitem-label.clock-icon {
        width: 26px;
        height: 26px;
    }

    .main__subitem-list.person > .main__subitem-li > .main__subitem-label.clock-icon > svg {
        transform: scale(1);
        width: 22px;
        height: 22px;
    }

    .main__left.left-side .Collapsible__trigger {
        font-size: 12px;
    }
    .main__left.left-side .Collapsible{
        margin-left: 10px;
        padding: 10px 0;
        cursor: pointer;
        position: relative;
    }
}

@media (max-width: 512px) {
    .main__subitem-list.person > .main__subitem-li > .main__subitem-label.clock-icon {
        width: 17px;
        height: 24px;
    }

    .main__subitem-list.person > .main__subitem-li > .main__subitem-label.clock-icon > svg {
        width: 16px;
        height: 16px;
    }
}

.forgot-subtitle {
    font-size: 30px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 10px;
    font-weight: normal;
    letter-spacing: 5px;
    text-align: center;
}
.forgot-descr, .forgot-email {
    font-size: 18px;
    text-align: center;
    color: #fff;
}
.forgot-descr {
    max-width: 500px;
    margin-bottom: 40px;
}
.forgot-email {
    margin-bottom: 20px;
}
.phone-box{
    width: 450px;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-bottom: 0 !important;
}
/*update 16/11/23 */
@media (max-width: 512px) {
    .phone-box{
        width: 100%;
        align-items: flex-end;
        display: flex;
        flex-direction: column;
    }
}
/*update 16/11/23 end*/

.account {
    overflow: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: url(../../assets/imgs/bg.jpg) no-repeat center / cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.account-content {
    flex-grow: 1;
    max-width: 520px;
    width: 100%;
}

.account-content form .error_zone{
  width: 100%;
}

.language-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: -50px;
}
.account-content  .page-title {
    font-Size: 36px;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: normal;
    color: #fff;
}

.account-content .switchButtonContainer {
    width: 450px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
}

.account-content .switchButton {
    width: 210px;
    height: 45px;
    border: 1px solid #fff;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}
 .account-content .switchButtonActive {
    width: 210px;
    height: 45px;
    border: 1px solid #fff;
    border-radius: 50px;
    color: #000;
    background-color: #fff;
     font-size: 16px;
     font-weight: 400;
}

.account-header {
    position: relative;
    width: 100%;
    /*padding-top: 20px;*/
    margin-bottom: 10px;
}

.account-header-first-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.account-header>div:first-child{
    text-align: right;
    padding: 20px;
}
.account-title {
    font-size: 80px;
    font-weight: 900;
    letter-spacing: 5.3px;
    color: transparent;
    -webkit-text-stroke: 1px #fff;
    text-transform: uppercase;
    text-align: center;
}
.account-about {
    position: relative;
    /*right: 30px;*/
    /*top: 35px;*/
    color: #fff;
    padding: 20px 0;
    border-bottom: 1px solid #fff;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    margin-top: 10px;
}
.account-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 700px;
}
.account-subtitle {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1.5px;
    text-align: center;
    margin-bottom: 35px;
}
.account-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 450px;
    width: 100%;
    margin-bottom: 30px;
}
.account-input {
    display: block;
    width: 100%;
    height: 52px;
    background-color: rgba(217, 217, 217, 0.25);
    border: 1px solid #fff;
    font-size: 16px;
    font-weight: 300;
    border-radius: 30px;
    padding: 5px 0px;
    color: #fff;
    text-align: center;
}
.error-text{
    margin-bottom: 20px;
    color: red;
    font-size: 12px;
}
.account-input::placeholder {
    color: white;
    font-size: 18px;
    font-weight: 300;
}
.account-input:focus::placeholder {
    opacity: 0;
}
.date-picker::placeholder {
    color: white;
}
.date-picker:focus::placeholder {
    opacity: 0;
}
.remember-user__box{
    padding-left: 1.8rem;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
}
.remember-user__box input{
    position: absolute;
    z-index: 2;
    width: 18px;
    height: 18px;
    opacity: 0;
    left: 0;
    top: 0.07rem;

}
/* Hide the browser's default checkbox */
.remember-user__box input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    visibility: hidden;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 5px;
    left: 0.09rem;
    height: 15.5px;
    width: 15.5px;
    background-color: #D9D9D940;
    border-radius: 2px;
}



/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.remember-user__box input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.remember-user__box .checkmark:after {
    left: 5.5px;
    top: 1.5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    scale: 0.8;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}
.remember-user__box  label{
    font-size: 1rem;
    position: static;
    color: white;
}
.remember-user__box  label::before {
    border-radius: 3px;
    left: 0px;
    width: 18px;
    height: 18px;
    top: 4px;
    background-color: #D9D9D940;
    position: absolute;
    display: block;
    pointer-events: none;
    content: "";
    border: 1px solid white;
}
.remember-user__box input:checked~label:before {
    border-color: #d59c00;
    background-color: #d59c00;
    box-shadow: 0 2px 4px 0 rgba(213,156,0,.4)!important;
}
.account-form .select {
    margin-right: 95px;
}
.account-form .select span {
    margin-left: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
}

.account-form .select:last-child {
    margin-right: 0;
}

.account-input::placeholder {
    text-align: center;
    color: inherit;
}
.account-submit {
    display: block;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    background-color: #42D3B0;
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
}
.account-submit:active {
    background-color: var(--main-color);
}
.reg {
    text-transform: uppercase;
    color: #fff;
    font-size: 18px;
    line-height: 1;
}
.reg:hover {
    text-decoration: underline;
}

.flagButton  {
    border: none !important;
    background-color: transparent !important;
    padding-left: 15px !important;
}

.flagButton div{
    background-color: transparent !important;
}
.phone-input-box{
  width: 450px;
  align-items: flex-end;
  display: flex;
  margin-top: -10px;
  flex-direction: column
}
.phoneContainer {
    border-radius: 50px !important;
    margin-top: 10px;
    margin-bottom: 10px;
}

.phoneInput {
    border-radius: 50px !important;
    background-color: rgba(217, 217, 217, 0.25) !important;
    width: 100% !important;
    height: 52px !important;
    padding-left: 57px !important;
    border: 1px solid #fff !important;
    color: #FFFFFF !important;
    font-size: 16px !important;
    line-height: 1.5;
    font-weight: 300;
    font-family: 'Noto Sans Armenian', sans-serif;
}
.phoneInputError {
    border-radius: 50px !important;
    background-color: rgba(217, 217, 217, 0.25) !important;
    width: 100% !important;
    height: 52px !important;
    padding-left: 57px !important;
    border: 1px solid #f26363 !important;
    color: #FFFFFF !important;
    font-size: 16px !important;
    line-height: 1.5;
    font-weight: 300;
    font-family: 'Noto Sans Armenian', sans-serif;
}
.phoneInputHighlighted {
    border-radius: 50px !important;
    background-color: rgba(217, 217, 217, 0.25) !important;
    width: 100% !important;
    height: 52px !important;
    padding-left: 57px !important;
    border: 1px solid #77FF95 !important;
    color: #FFFFFF !important;
    font-size: 16px !important;
    line-height: 1.5;
    font-weight: 300;
    font-family: 'Noto Sans Armenian', sans-serif;
}
.emailInputHighlighted {
    display: block;
    width: 100%;
    height: 52px;
    background-color: rgba(217, 217, 217, 0.25);
    border: 1px solid #77FF95;
    font-size: 18px;
    font-weight: 300;
    border-radius: 30px;
    padding: 5px 0px;
    color: #fff;
    text-align: center;
    margin: 10px 0;
}

.phone-edit {
    border-radius: 30px !important;
    width: 100% !important;
    height: 52px !important;
    margin-left: 10px !important;
}

.phoneNumberInput:focus::placeholder {
    opacity: 0;
}
.phoneNumberInputError {
    text-align: center;
    border-left: 1px solid #f26363;
    width: 75%;
    padding: 0px 110px 0 10px;
    height: 52px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    margin-top: -100px;
    margin-bottom: 40px;
    z-index: 2;
}

.phoneNumberInputError::placeholder {
    color: inherit;
    font-size: 14px;
    font-weight: 300;
    text-align: start;
    overflow: visible;
}
.phoneNumberInputError:focus::placeholder {
    opacity: 0;
}
.emailInput:focus::placeholder {
    opacity: 0;
}
.emailError:focus::placeholder {
    opacity: 0;
}

.phoneNumberInput {
    border-left: 1px solid #fff;
    padding: 0px 5px 0 75px;
    width: 75%;
    height: 52px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    margin-top: -62px;
    margin-bottom: 10px;
    z-index: 2;
    text-align: start;
}

.phoneNumberInput:placeholder-shown{
    padding: 0px 10px 0 10px;
}
.phoneNumberInput:focus{
    padding: 0px 110px 0 10px;
}

.red {
    border-left: 1px solid #f26363 !important;
}

.emailInput {
    display: block;
    width: 100%;
    height: 52px;
    background-color: rgba(217, 217, 217, 0.25);
    border: 1px solid #fff;
    font-size: 18px;
    font-weight: 300;
    border-radius: 30px;
    padding: 5px 0px;
    color: #fff;
    text-align: center;
    margin: 10px 0;
}

.emailInput::placeholder {
    text-align: center;
    color: inherit;
}
.emailError::placeholder {
    text-align: center;
    color: inherit;
}

.emailError {
    margin: 10px 0;
    border-radius: 50px;
    background-color: rgba(217, 217, 217, 0.25);
    width: 100%;
    height: 52px;
    border: 1px solid #f26363;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    font-family: 'Noto Sans Armenian', sans-serif;
    text-align: center;
}

.account-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 60px 20px 30px;
}
.account-socials {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
}
.social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
}
.social-link svg {
    stroke: #fff;
    stroke-width: 1.5px;
}
.account-powered {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 3px;
    color: #fff;
    transform-origin: center left;
    transform: rotate(-90deg);
}
.input-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    text-align: right;
}
.input-wrapper .MyDatePicker-icon{
    position: absolute;
    right: 10px;
    top: 0;
    height: 53px;
    width: 25px;
}
.input-wrapper .MyDatePicker-icon svg{
    position: absolute;
    right: 0;
    top: 50%;
    translate: -50% -50%;
}
.error-btn {
    position: absolute;
    top: 33%;
    transform: translateY(-50%);
    right: 20px;
    display: none;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: url('../../assets/imgs/error.svg') no-repeat center / contain;
}


.user-input .error-btn {
    right: 5px;
    width: 25px;
    height: 25px;
}

.input-wrapper.error .account-input {
    border-color: #f26363;
}
.input-wrapper.error .error-btn {
    display: block;
}
.user-input.error .error-btn {
    display: block;
}
.error-btn:hover + .error-msg,
.error-btn:focus + .error-msg {
    display: block;
}
.account-input.error .account-input {
    border-color: #f26363;
}
/* Customize the label (the container) */
.container-terms-policy {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container-terms-policy input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
w
.input_pass_container {
    position: relative;
}

.password_icon_container {
    width: 25px;
    height: 52px;
    position: absolute;
    top: 0%;
    right: 20px;
    cursor: pointer;
}
.password_icon_container img{
    width: 20px;
    height: 13px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
}

/* Create a custom checkbox */
.container-terms-policy__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
    transition: .2s;
}

/* On mouse-over, add a grey background color */
.container-terms-policy:hover input ~ .container-terms-policy__checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-terms-policy input[data-active="true"] ~ .container-terms-policy__checkmark {
    background-color: #fff;
}
.container-terms-policy .container-terms-policy__checkmark[data-error="true"]  {
    background-color: #f26363!important;
}

.react-tel-input .country-list {
    margin: 55px 0 10px -1px !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.container-terms-policy__checkmark:after {
    content: "";
    position: absolute;
    display: none;
    transition: .2s;
}

/* Show the checkmark when checked */
.container-terms-policy input[data-active="true"] ~ .container-terms-policy__checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container-terms-policy .container-terms-policy__checkmark:after {
    left: 10px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #baacaa;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.terms-police-title{
    color: #fff;
    font-size: 16px;
    line-height: 1;
    text-decoration: underline;
    cursor: pointer;
}

.square-checkbox {
    width: 15px;
    height: 15px;
    border: 0.5px solid #FFF;;
}
/*.square-checkbox input[type="checkbox"] {*/
/*    position: absolute;*/
/*    !*opacity: 0;*!*/
/*    cursor: pointer;*/
/*}*/

/*.square-checkbox label {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background-color: #ffffff; !* Background when unchecked *!*/
/*    border: 1px solid #333; !* Border color *!*/
/*}*/

/*.square-checkbox input[type="checkbox"]:checked + label {*/
/*    background-color: #007bff; !* Background when checked *!*/
/*}*/

/*!* Optional: Add custom styles for the checkmark *!*/
/*.square-checkbox label::after {*/
/*    content: '\u2714'; !* Checkmark symbol *!*/
/*    font-size: 14px;*/
/*    color: #fff; !* Checkmark color when checked *!*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*}*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
@media (max-width: 768px) {
    .account-content {
        padding: 0 20px
    }
    .account-footer {
        padding: 40px 60px 20px 10px;
    }
    .account-title {
        font-size: 30px;
        position: relative;
    }
    .popup-title {
        font-size: 30px;
    }
    .account-subtitle {
        font-size: 20px;
        padding-top: 60px;
    }
    .error-msg {
        right: 65px !important;
    }

    .user-left {
        flex-grow: unset !important;
        flex-shrink: unset !important;
        width: 90% !important;
        margin-top: 80px;
        margin-bottom: 20px;
        height: auto !important;
    }
    .user-right {
        flex-grow: unset !important;
        flex-shrink: unset !important;
        width: 90% !important;
        margin-bottom: 80px;
        height: auto !important;
    }
    .user-input {
        width: auto !important;
    }
    .user-pass .user-detail {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .account-about{
        top: 0;
        right: 20px;
    }
}

.email-box{
    width: 450px;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
}
.phone-box{
    width: 450px;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin: 0;
}

.account-header-language-switcher{
    position: absolute; /* Allow absolute centering */
    right: 20px;
    top: 0px;
    margin: 0; /* Remove default margins for proper centering */
}
.user-detail-input-change {
  width: 65% !important;
}

.user-input.select {
  width: 100% !important;
  margin: 0 !important;
}

.user-input.user-detail-input-change {
  margin: 0 !important;
  padding: 4px 0px;
}

.user-input.user-detail-input-change>div>.react-datepicker-wrapper {
  padding-left: 20px;
  width: 100%;
}

.user-input.user-detail-input-change>.MyDatePicker-icon {
  position: absolute;
  right: 10px;
}

.account-dashboards-new {
  z-index: 1;
  background-color: #fff;
  display: flex;
  align-items: stretch;
  min-height: 700px;
  position: absolute;
  width: 80%;
  top: 140px;
  border-radius: 5px;
    padding-bottom: 10px;
}
.user_pass_zone{
  width: 100% !important;
}
.user_pass_zone form{
  width: 100% !important;
}

.user-detail-title{
  width: 570px;
}

.account-dashboards-new .user-right, .account-dashboards-new .user-left{
  /*min-height: 700px !important;*/
}

@media screen and (min-width: 900px) and (max-width: 1800px) {

    .account-dashboards-new{
        /* padding: 105px 105px 50px; */
    }
}

@media (max-width: 1024px) {
  .dashboard-list {
    min-height: 88px !important;
    padding-bottom: 34px !important;
  }

  .account-dashboards {
    background-color: transparent !important;
  }
  .account-dashboards-new {
    background-color: transparent !important;
  }
  .account{
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  .account-dashboards {
      position: relative !important;
      flex-direction: column !important;
      padding: 120px 20px 90px !important;
      width: 100% !important;
      overflow: unset !important;
      padding: 0px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      padding-top: 120px !important;
    }
  .account-dashboards-new {
      position: relative !important;
      flex-direction: column !important;
      padding: 120px 20px 90px !important;
      width: 100% !important;
      overflow: unset !important;
      padding: 0px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    .account-dashboard {
      width: 100% !important;
      margin: 0 !important;
      margin-bottom: 10px !important;
      max-height: 600px !important;
    }

    .user-left{
      width: 100% !important;
      margin-top: 0 !important;
      margin-bottom: 0px !important;
      height: auto !important;
    }
    .user-right{
      width: 100% !important;
      height: auto !important;
    }
    .user-detail{
      display: flex !important;
      justify-content: space-between !important;
    }
    .user-input {
      width: unset !important;
        text-align: right;
    }
     .bookmark-dashboards > .account-dashboards--content{
      grid-template-columns: repeat(2, calc(50% - 10px)) !important;
    }
    .bookmark-dashboards{
      height: calc(100% + 175px) !important;
      position: relative !important;
      overflow-y: auto !important;
      flex-direction: column !important;
      min-width: 96% !important;
      display: grid !important;
      grid-template-rows: repeat(2, auto) !important;
      gap: 20px !important;
    }
    .bookmark-dashboards .account-dashboards--content{
      min-width: 50% !important;
      display: grid !important;
      grid-template-rows: repeat(2, auto) !important;
      gap: 20px !important;
    }
    .bookmark-dashboards {
      margin: 120px 15px 20px !important;
    }

    .user-pass-text {
      font-size: 12px !important;
      margin-top: 10px;
    }
  .user-pass > form  {
      font-size: 12px !important;
      margin-top: 10px;
    }

    .user-pass-btn {
      font-size: 14px !important;
      padding: 10px 20px !important;
      background: #42D3B0;
    }

    .user-pass {
      width: 100% !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
    .user-detail > .user-input.user-detail-input-change{
      width: 50% !important;
    }
    #school-other .error_zone{
      width: 50% !important;
    }
    .user-detail-input-change {
      width: 50% !important;
    }

    .user_pass_zone form .user-detail .error_zone {
      width: 200px;
    }

    .user-form-btns{
      justify-content: center !important;
    }

    .user_pass_zone form  {
      width: 90% !important;
    }

    .account-dashboards-new .user-left .user-photo{
      width: 300px !important;
      margin: 10px auto !important;
    }

    .account-dashboards-new .user-right,
    .account-dashboards-new .user-left {
      height: unset !important;
    }

}

@media (max-width: 900px) {


  /* .main__tab-btn{
    width: 40px !important;
    height: 40px !important;
    z-index: 2 !important;
  }
  .header__burger {
    width: 40px !important;
    height: 40px !important;
  }
  .main__tab-btn svg {
    width: 18px !important;
    height: 18px !important;
  } */
  .acc-footer__copy {
    font-size: 10px;
  }

  .user-detail-title {
    width: 120px !important;
  }
}


@media (max-width: 512px) {
    .language-container {
        margin-right: 35px;
    }
  .bookmark-empty-data h2 {
    font-size: 16px !important;
    text-align: center !important;
  }
  .main__tab-btn__title{
    font-size: 8px;
  }
  .top-btns>div {
    margin-left: 0 !important;
    margin-right: 20px !important;
  }
  .top-btns {
    padding-left: 65px;
  }
  .user-additional-content > .user-detail {
    font-size: 10px !important;
  }

  .user-input.border-none {
      margin-left: 0 !important;
  }
  .user-pass{
    margin: 0 auto !important;
  }

  .user-right-header .user-additional,.user-info,.user-additional-content{
    font-size: 10px !important;
  }
  .user-form-btns .user-form-btn,.user-form-btns .user-edit-btn,.user-name,.user-role,.user-contacts{
    font-size: 12px !important;
    width: 100% !important;
  }
  .user-pass-text{
    font-size: 12px !important;
    margin-top: 10px;
  }
  .user-pass{
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .bookmark-dashboards {
    display: grid !important;
  grid-template-columns: repeat(1, 100%) !important;
    gap: 20px !important;
  }

  .bookmark-dashboards .account-dashboards--content {
    display: grid !important;
    grid-template-columns: repeat(1, 100%) !important;
      gap: 20px !important;
  }

  .header__nav{
    width: 300px !important;
  }

  .header__title.title{
    font-size: 18px !important;
  }

  .header__burger.active{
    left: 300px !important;
    margin-top: max(calc(100vh/130), 5px) !important;
  }

  .user-right > .user-additional-content > form .user-detail {
    flex-direction: column !important;
    align-items: center !important;
  }

  .user-right>.user-additional-content>form .user-detail > .user-input {
    padding: 4px 20px !important;
    width: 230px !important;
  }

  .user-right>.user-additional-content>form .user-detail>.user-input .MyDatePicker-icon {
    position: absolute;
    right: 12px;
  }

  .user-detail-input-change {
    width: 230px !important;
    margin-top: 10px !important;
  }

  .account-info-content{
    font-size: 10px !important;
  }
  .account-info-content .account-info span{
    font-size: 10px !important;
  }

  .user-input.user-detail-input-change>div>.react-datepicker-wrapper {
    padding-left: 0px !important;
  }

  .user-detail-title {
    width: 10px !important;
  }

}
/*update 16/11/23 */
@media (max-width: 512px) {
    .account-content .switchButtonContainer{
        width: 100%;
        gap: 15px;

    }
    .account-subtitle{
        padding-top: 0px;
        font-size: 16px;
        margin-bottom: 65px;
    }
    .account-title{
        font-size: 30px;
        margin-top: 100px;
    }
    .phone-input-box{
        width: 100%;
    }
    .remember-user__box{
        margin-bottom: 30px;
    }
    .phoneNumberInput{
        width: 73%;
        font-size: 12px;
    }
    .speciality-box{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .speciality-box .select{
        padding: 0 5px;
        text-wrap: nowrap;
        display: flex;
        flex-flow: nowrap;
        white-space: nowrap;
        margin: 0;
    }
    .email-box{
        width: 100%;
        align-items: flex-end;
        display: flex;
        flex-direction: column;
    }
    .phone-box{
        width: 100%;
        align-items: flex-end;
        display: flex;
        flex-direction: column;
    }
    .account-dashboards-new .user-right, .account-dashboards-new .user-left{
        /*min-height: 400px !important;*/
        border-radius: 0px;
        margin: 0;
    }
    .account .header__burger{
        width: 36px !important;
        height: 36px !important;
        margin-left: 8px!important;
        font-size: 20px;
    }
    .user-input.user-detail-input-change{
        width: 100%;
        padding-right: 50px;
    }
}
@media (max-height: 512px) {
    .account-dashboards {
        padding: 100px 20px 90px !important;
    }

}
/*update 16/11/23 end*/

@media (max-width: 370px) {
  .header__nav {
    width: 250px !important;
  }
  .header__burger.active {
    left: 250px !important;
    margin-top: max(calc(100vh/130), 5px) !important;
  }
    .user-detail-title {
      width: 10px !important;
    }
}


/* .user_pass_zone{
  width: 100% !important;
  height: fit-content !important;
} */

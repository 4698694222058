.scrollable__container {
    align-self: flex-start;
    overflow-x: auto;
    width: calc(100% + 8px);
    contain: content;
}

.draggable__squares {
    display: flex;
}

.mini__square {
    display: flex;
    width: 63px;
    height: 58px;
    margin: 1px;
    background-color: #F7F7F7;
    cursor: pointer;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
    justify-content: flex-end;
}

.empty__square {
    margin-bottom: 10px;
}
.infinite-scroll-bar{
    display: flex;
}
.mini__square__div {
    padding: 2px;
    border: 1px solid #24B263;
    border-radius: 8px;
}
.mini__square__div button[data-disable='true']{
    opacity: 0.5;
}
.show__info__icon {
    display: flex;
    width: 18px;
    height: 18px;
    margin: 3px 3px 0 0;
    z-index: 5;
}

.square__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 5px;
}


.square__container p {
    display: -webkit-box;
    color: #6E6B7B;
    font-family: Mardoto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    width: 63px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-align: center;
}
.square__container .square__container__text[data-is-loading="true"] {
    height: 15px;
}

.option__menu__square {
    width: 57px;
    height: 52px;
    cursor: pointer;
    border-radius: 5px;
    background-size: cover;
}

.option__menu__container {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: rgba(44, 71, 108, 0.80);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.option__menu__container button {
    width: 26%;
    height: 28%;
    margin: 0 2px
}

.scrollable__container div::-webkit-scrollbar,
.draggable__squares::-webkit-scrollbar {
    height: 5px;
}

.scrollable__container div::-webkit-scrollbar-thumb,
.draggable__squares::-webkit-scrollbar-thumb {
    background-color: #314769;
    border-radius: 6px;
}

.scrollable__container div::-webkit-scrollbar-track,
.draggable__squares::-webkit-scrollbar-track {
    background-color: #F7F7F7;
    border-radius: 6px;
}


.under-construction--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
}

.under-construction--container h1 {
    font-size: 46px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
}

.under-construction--video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.5);
    z-index: -1;
}


@media screen  and (max-width: 768px) {
    .under-construction--container h1 {
        font-size: 24px;
    }
}

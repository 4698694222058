.success-alert {
    padding: 10px 20px;
    color: #fff;
    display: flex;
    font-size: 14px;
    max-width: 400px;
    text-align: left;
    margin-bottom: 20px;
    background: #22bb33;
    position: absolute;
    font-weight: 300;
    right: 20px;
    top: 20px;
    border-radius: 15px;
    z-index: 9;
    cursor: pointer ;
    animation-name: hideByOpacity;
    animation-duration: 5000ms;
}

@keyframes hideByOpacity {
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}

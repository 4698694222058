/*LINE ARROW*/

.line-arrow--target {
    height: 100%;
}

.line-arrow--target .line-arrow--line {
    height: 100%;
    flex:1
}

.line-arrow--target .line-arrow--arrow {
    height: 100%;
}

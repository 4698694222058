section.quiz-content {
    align-items: flex-start;
    justify-content: center;
    z-index: 1;
    padding: 0px 0px min(8vh,70px) 0px;
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: min(8vh,70px) calc(100vh - min(8vh,70px)*2);
}
section.quiz-content .quiz-header{
    width: 100%;
    height: min(8vh,70px);
    position: relative;
    left: 0;
    top: 0;
    display: grid;
    justify-content: flex-end;
    grid-template-columns: 1fr 50px;
    grid-gap: 10px;
    align-items: center;
    z-index: 1;
}
section.quiz-content .quiz-header .quiz-header-title {
    opacity: 1;
    color: rgba(255, 255, 255, 1);
    font-family: "NotoSansArmenian-Bold";
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    padding: 0px 5vw;
}
section.quiz-content .quiz-header .quiz-header-btn {
    position: relative;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
}
section.quiz-content .quiz-header .quiz-header-btn svg{
    width: 100%;
    height: 100%;
}
section.quiz-content .quiz-body{
    width: 90%;
    min-height: 300px;
    background-color: white;
    margin: auto;
    height: 100%;
    padding: 10px;
    box-shadow: 0px 7px 26px rgb(44 71 108 / 10%);
    border-radius: 10px;
}
.card-body__dynamic-title.dynamic-title{
    display: flex;
    width: 100%;
    height: max-content;
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 900;
    font-size: max(12px,calc(100vh/193));
    text-transform: uppercase;
    color: #2C476C;
    white-space: nowrap;
    justify-content: space-between;
    align-items: flex-start;
}
.card-body__dynamic-title.dynamic-title h4{
    max-width: 81%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1;
}
.hasLink .card-body__dynamic-title.dynamic-title{
    cursor: pointer;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .card-body__dynamic-title.dynamic-title{
        font-size: max(10px,calc(100vh/193));
    }
}
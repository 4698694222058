.card-body__dynamic-actions.dynamic-actions{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #2C476C;
    border-radius: 6px;
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 500;
    font-size: max(13px,calc(100vh/215));
    line-height: 15px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 8px;
    gap: 3px;
}
.card-body__dynamic-actions.dynamic-actions h6{
    cursor: pointer;
}

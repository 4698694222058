@keyframes ldio-4i2q8i173j7 {
    0% {
        top: 104.16px;
        left: 104.16px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 19.53px;
        left: 19.53px;
        width: 169.26px;
        height: 169.26px;
        opacity: 0;
    }
}.ldio-4i2q8i173j7 div {
     position: absolute;
     border-width: 4.34px;
     border-style: solid;
     opacity: 1;
     border-radius: 50%;
     animation: ldio-4i2q8i173j7 0.9615384615384615s cubic-bezier(0,0.2,0.8,1) infinite;
 }.ldio-4i2q8i173j7 div:nth-child(1) {
      border-color: #f3bfda;
      animation-delay: 0s;
  }
.ldio-4i2q8i173j7 div:nth-child(2) {
    border-color: #89daff;
    animation-delay: -0.4807692307692307s;
}
.loadingio-spinner-ripple-orgsc8fmyn {
    width: 217px;
    height: 217px;
    display: inline-block;
    overflow: hidden;
}
.ldio-4i2q8i173j7 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-4i2q8i173j7 div { box-sizing: content-box; }
/* generated by https://loading.io/ */

.slide-settings-content {
    margin: 50px 12vw;
    width: 20vw;
    min-width: 250px;
}

.slider__settings {
    gap: 0 !important
}

.first__line {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.first__line > * {
    width: 33%;
    min-width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.first__line h5 {
    color: #6E6B7B;
    font-family: Mardoto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    text-transform: capitalize;
    align-self: center;
    /*margin-top: -20px;*/
}

.radio__buttons {
    margin-left: 20px;
}
.radio__buttons label {
    padding: 0;
}
.radio__buttons label span {
    padding: 0;
    font-family: Mardoto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}
.radio__buttons label span:last-child {
    padding: 0;
    font-family: Mardoto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    color: #6E6B7B;
}

.switcher {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.switcher p {
    color: #6E6B7B;
    font-family: Mardoto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.edit__and__delete {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.edit__and__delete p {
    color: #6E6B7B;
    font-family: Mardoto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.edit__and__delete button {
    height: 18px;
    width: 18px;
}

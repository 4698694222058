.elements-popup-radius .mapboxgl-popup-content{
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    position: absolute;
}
.elements-popup-radius-box{
    position: relative;
    top: 0;
    left: 0;
    width: 0px;
    height: 0px;
}
.elements-popup-radius-content {
    width: 40px;
    height: 40px;
    position: absolute;
    left:calc(var(--left) + 50%);
    top: calc(var(--top) + 50%);
    transform: var(--rotate)  rotateY(0deg);
    border-radius: 200px;
    translate: -50% -50%;
    animation: animateElements 0.5s ease-in-out;
    /*transition: 0.3s;*/
    /*transform: rotate(0deg);*/
    scale: 0.9;
}
.elements-popup-radius-content__line{
    width: 1px;
    height: 60px;
    background-color: #2C476C80;
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50%;
    z-index: 0;

    /*width: 1px;*/
    /*height: 76px;*/
    /*background-color: #fb0212;*/
    /*position: absolute;*/
    /*left: 50%;*/
    /*top: 50%;*/
    /*translate: -50%;*/
    /*z-index: 0;*/
}
.elements-popup-radius-content__info{
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 200px;
    padding: 10px;
    z-index: 3;
    transform: var(--radians);
    transition: 0.2s;
    cursor: pointer;
}
.elements-popup-radius-content[data-active='true'] .elements-popup-radius-content__info{
    background-color: var(--color);

}
.elements-popup-radius-content__info:hover{
    background-color: var(--color);
}
.elements-popup-radius-content__info:after{
    content: attr(data-title);
    position: absolute;
    left: 50px;
    top: 50%;
    white-space: nowrap;
    translate: 0 -50%;
    font-family: 'GheaGrapalat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.1rem;
    color: var(--text-color);
    text-shadow: 1.3px 1.3px 0px var(--text-halo-color), -1.3px -1.3px 0px var(--text-halo-color), 1.3px -1.3px 0px var(--text-halo-color), -1.3px 1.3px 0px var(--text-halo-color);
}
[data-left='false']:after{
    content: attr(data-title);
    position: absolute;
    left: 0px;
    top: 50%;
    white-space: nowrap;
    translate: -110% -50%;
}
/*.elements-popup-radius-content__info:before{*/
/*    content: "";*/
/*    position: absolute;*/
/*    left: 50%;*/
/*    top: 50%;*/
/*    translate: -50% -50%;*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    background-image: var(--icon);*/
/*    background-position: center;*/
/*    background-size: contain;*/
/*    background-repeat: no-repeat;*/
/*    transition: 0.2s;*/
/*}*/
.elements-popup-radius-content__info:hover:before{
    filter: brightness(10);
}
@keyframes animateElements {
    from{
        transform: var(--rotate) rotateY(90deg);
    }
    to{
        transform: var(--rotate) rotateY(0deg);
    }
}

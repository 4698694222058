.card-body__dynamic-info.dynamic-info{
    display: flex;
    width: 100%;
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-size: max(12px,calc(100vh/193));
    line-height: 24px;
    text-transform: uppercase;
    color: #2C476C;
}
.card-body__dynamic-info.dynamic-info h4{
    white-space: nowrap;
    font-weight: 900;
}
.card-body__dynamic-info.dynamic-info p{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.screenshot_body_content{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width: 900px) and (max-width: 1800px)  {
    .card-body__dynamic-info.dynamic-info {
        line-height: calc(100dvh/60 );
        /*font-size: max(10px,calc(100vh/193));*/
        font-size: calc(100dvh/80);
        margin-bottom: 3px;
    }
}

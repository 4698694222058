.card-body__date-info.date-info{
    display: flex;
    width: 100%;
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    line-height: 21px;
    color: #2C476C;
    font-size: max(11px,calc(100vh/215));
    justify-content: flex-start;
    padding-right: 4px;
}
.card-body__date-info.date-info h5{
    white-space: nowrap;
    color: #2C476C;
    font-weight: 900;
    font-size: 10px;
    width: 80px;
}
.card-body__date-info.date-info p{
    white-space: nowrap;
    overflow: hidden;
    font-size: 10px;
    font-weight: 500;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .card-body__date-info.date-info{
        line-height: 17px;
        font-size: max(9px,calc(100vh/215));
    }
}

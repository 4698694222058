.language-selector {
    position: relative;
}

.dropdown-button {
    cursor: pointer;
    margin: 15px 20px 0 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropdown-menu {
    flex-direction: column;
    margin-top: 15px;
    position: absolute;
    top: 30px;
    border: 1px solid #FFFFFF8F;
    left: calc(50% - 18px);
    padding: 8px 10px;
    justify-content: space-between;
    height: 80px;
    border-radius: 8px;
    background: rgba(217,217,217, 0.25);
    display: none
}

.dropdown-menu img {
    width: 23px;
    height: 23px;
}
.open {
    display: flex;
}

.dropdown-item {
    cursor: pointer;
    margin-top: 5px;
}

@media (max-width: 582px) {
    .dropdown-button {
        margin: 15px 0 0;
    }
}

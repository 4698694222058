.no-language-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 500px;
    height: 300px;
    background-color: #F3F2F7;
    padding: 20px;
    border-radius: 15px;
}
.no-language-content-title{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.no-language-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border: 1px solid;
    border-radius: 100%;
    margin: 20px 0px;
}
.no-language-content-btn{
    border: 1px solid;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #F3F2F7;
    cursor: pointer;
    min-width:100px ;
    transition: 0.3s;
}
.no-language-content-btn:hover{
    background-color: #e6e6e6;
}
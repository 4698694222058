.card-body__copy-share-link.copy-share-link{
    display: grid;
    gap: 10px;
}
.card-body__copy-share-link.copy-share-link button{
    background-color: #6EC53D;
    border-radius: 11px;
    padding-left: 15px;
    padding-right: 15px;
}
.copy-share-link__btn-box{
    position: relative;
}
.copy-share-link__btn-box>div{
    width: 100%;
    padding: 0;
    border-radius: 11px;
}
.copy-share-link__btn-box input{
    padding: 6px;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .card-body__copy-share-link.copy-share-link h5{
       font-size: 13px;
    }
}
.card-body__edit-screenshot-title-form.edit-screenshot-title-form{
    margin: 15px 0;
    display: grid;
    gap: 20px;
}
.card-body__edit-screenshot-title-form.edit-screenshot-title-form  button{
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
    width: 120px;
}
.edit-screenshot-title-form__btn-box{
    position: relative;
}
.edit-screenshot-title-form__btn-box>div{
    width: 100%;
    padding: 0;
    border-radius: 11px;
}
.edit-screenshot-title-form__btn-box input{
    padding: 6px;
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: #2C476C;
}
.card-body__edit-screenshot-title-form.edit-screenshot-title-form button{
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
}

@media screen and (min-width: 900px) and (max-width: 1800px) {
    .card-body__edit-screenshot-title-form.edit-screenshot-title-form h5{
       font-size: 13px;
    }
}
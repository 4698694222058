.card-body__dynamic-screen-shot-remove-content.dynamic-screen-shot-remove-content {
    background: #F5F5F5;
    border-radius: 11px;
    width: 500px;
}
.card-body__dynamic-close-btn-box{
    height: 20px;
}
.card-body__dynamic-close-btn-box svg{
    height: 100%;
    width: 100%;
}
.dynamic-screen-shot-remove-content__header h4{
    font-size: 16px;
}
.remove_screenShot_card_body__dynamic-title{
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}
.remove_screenShot_card_body_dynamic_sub_title{
    font-weight: normal;
    margin-top: 20px;
    font-size: 12px;
    text-transform: none;
}

.screenshot_zone{
  display: flex !important;
  justify-content: center !important;
  font-weight: normal;
  margin-top: 20px;
  font-size: 12px;
}

.screenshot_buttons{
  padding: 10px 0px;
  border-radius: 6px;
  width: 100px;
}

.screenshot_cancel{
  border: 1px solid #B2B2B2;
  border-radius: 6px;
  margin-right: 10px;
}


.screenshot_remove{
  background-color: red;
  color: #fff;
}

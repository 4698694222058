.painter_tools_container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 66px;
    width: 232px;
    /*height: 110px;*/
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 7px 26px rgba(44, 71, 108, 0.1);
    z-index: 5;
    left: 0;
}

@media screen and (max-width: 1024px) {
    .painter_tools_container {
        display: flex;
        flex-direction: column;
        /* TRANSFORM USED FOR FIX BUG ON IOS DEVICES*/
        position: fixed;
        position: -ms-device-fixed;
        top: 60px;
        left: -90px;
        width: 232px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 7px 26px rgba(44, 71, 108, 0.1);
        z-index: 5;
    }
}

@media screen and (max-width: 512px) {
    .painter_tools_container {
        transform: scale(0.9);
    }
}

.painter_tools_size {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
}

.painter_tools_size_icon {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 76%;
    background-position: center;
}

.painter_tools_size_icon_block {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
}

.painter_tools_size_icon_block:hover {
    background-color: #2c476c;
    transition: ease-in-out 0.2s;
}

.painter_tools_size_icon_block:hover.painter_tools_size_icon_block img {
    filter: brightness(0) invert(1);
}

.painter_tools_color_item {
    width: 21px;
    height: 21px;
    position: relative;
    z-index: 1;
}

.painter_tools_color_item:hover {
    border: 2px solid white;
}

.painter_tools_color_item_container span {
    width: 22px;
    height: 22px;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
    left: 80.5%;
    top: 0;
    background: #fff;
}

.color_circle {
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.painter_tools_color_item_container {
    padding: 10px 12px;
    background: #fff;
    position: absolute;
    width: 276px;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 1;
    top: 130px;
    left: -20px;
    box-shadow: 0px 7px 26px rgba(44, 71, 108, 0.1);
}

.painter_tools_color {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 13px;
    border-end-end-radius: 10px;
    border-end-start-radius: 10px;
    z-index: 2;
}

.painter_tools_color_data_item {
    padding: 1px;
    border-radius: 50px;
    box-sizing: border-box;
    min-width: 20px;
    min-height: 20px;
}

@media (max-width: 1024px) {

    .color_circle, .color_circle svg {
        width: 25px !important;
        height: 25px !important;
    }

    .painter_tools_color_item {
        width: 16px;
        height: 16px;
    }
}

.play_container{
    position: fixed;
    border-radius: 12px;
    background: #FFF;
    left: 115px;
    top: max(calc(100vh/216.2),5px);
    display: flex;
    align-self: flex-start;
    padding: 5px 8px;
}

.play_container button {
    height: 32px;
    width: 32px;
    align-self: center;
    padding-top: 2px;
}
.play_container button[data-is-loading="true"] {
    margin-right: 5px;
}
.play_container button:last-child {
    height: 16px;
    width: 16px;
    margin-left: 8px;
    padding: 0;
}

.hided_icon {
    margin: 0 !important;
    width: 28px !important;
    height: 32px !important;
    padding: 8px !important;
}

@media screen and (max-width: 1024px) {
    .play_container {
        top: 27px;
        transform: scale(0.9);
    }
}
.bookmark-info{
    display: grid;
    grid-gap: 10px;
}
.bookmark-info .hero__media_animation__box, .bookmark-info .hero__media__box {
    width: 50%;
    height: 100%;
    border-radius: 11px;
    padding: 3px;
}
.bookmark-info  .bookmark-image-box.hero__media_animation {
    width: 100%;
    height: 100%;
    border-radius: 11px;
}
.bookmark-info  .hero__media__liner-gradien{
    animation: none!important;
}
.bookmark-info .bookmark-image-box img{
    aspect-ratio: 332/188;
    border-radius: 11px;
    border: 3px solid white;
    object-fit: contain;
}
.bookmark-description-box{
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 500;
    font-size: max(12px,calc(100vh/193));
    line-height: 20px;
/* or 140% */
    letter-spacing: 1px;
    color: #101A29;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
@media screen and (min-width: 900px) and (max-width: 1800px){
    .bookmark-info{
        grid-gap: 5px;
    }
    .bookmark-info .hero__media_animation__box, .bookmark-info .hero__media__box {
        width: 40%;
    }
    .bookmark-description-box{
        font-size: max(10px,calc(100vh/193));
        line-height: 15px;
    }
}
.bookmark-content-info {
    display: grid;
    grid-template-columns: 85% 15%;
    grid-template-rows: 30px;
    justify-content: space-between;
}
.bookmark-content-info__category-info{
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;
}
.bookmark-content-info-category-info__icon{
    height: 100%;
    border-radius: 8px;
    width: 30px;
    height: 30px;
    background-color: var(--category-color);
    display: flex;
    align-items: center;
    justify-content: center;
}
.bookmark-content-info-category-info__icon img{
    aspect-ratio: 1/1;
    height: 100%;
    width: 20px;
    height: 20px;
}
.bookmark-content-info__category-info p{
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 700;
    font-size: max(12px,calc(100vh/193));
    line-height: 20px;
/* identical to box height, or 234% */
    text-align: left;
    text-transform: uppercase;
/* text main color in web */
    color: #101A29;
    width: 75%;
    text-overflow: ellipsis;
    overflow: hidden;

}
.bookmark-like-box{
    display: flex;
    justify-content: flex-end;
}
.bookmark-like-box svg{
    display: flex;
    align-items: center;
    justify-content: center;
}
.bookmark-content-info .sprite-icon {
    scale: 0.7;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .bookmark-content-info-category-info__icon{
        width: 27px;
        height: 27px;
    }
    .bookmark-content-info-category-info__icon img{
        width: 13px;
        height: 13px;
    }
    .bookmark-content-info__category-info p{
        font-size: max(10px,calc(100vh/193));
        line-height: 15px;
    }
}

.shape_selector--container {
  width: 810px;
  max-width: 100%;
  border-radius: 20px;
  box-shadow: 0px 4px 20px 0px #2c476c1a;
  background-color: white;
  padding-top: 10px;
  display: flex;
  height: 530px;
  max-height: 80vh;
  overflow: hidden;
}

.shape_selector--sidebar {
  width: 290px;
  padding: 5px;
  overflow-y: auto;
  padding-right: 15px;
  padding-left: 10px;
}

/*make scrollbar like mac system*/
.shape_selector--sidebar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #eaeaea;
}

.shape_selector--sidebar::-webkit-scrollbar-thumb {
  background-color: #9ea1a4;
  border-radius: 10px;
}

.shape_selector--sidebar::-webkit-scrollbar-track {
  background-color: #eaeaea;
  border-radius: 10px;
}



.shape_selector--sidebar-item {
  padding: 4px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 40px;
  font-size: 28px;
}

.shape_selector--sidebar-item.active {
  background-color: #2c476c;
  color: white;
}

.shape_selector--body {
  flex: 1;
  overflow-y: auto;
  padding-inline: 20px;
  background-color: #fff;
}

.shape_selector--body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #eaeaea;
}

.shape_selector--body::-webkit-scrollbar-thumb {
  background-color: #9ea1a4;
  border-radius: 10px;
}

.shape_selector--body::-webkit-scrollbar-track {
  background-color: #eaeaea;
  border-radius: 10px;
}

.shape_selector--category-section {
  padding: 4px 0;
}

.shape_selector--category-section:last-child {
  min-height: 500px;
  max-height: 100%;
}

.shape_selector--category-section h2 {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 5px;
  color: #9ea1a4;
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: 500;
}

.shape_selector--list {
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  justify-content: space-between;
}

.shape-item {
  width: 85px;
  height: 85px;
  /*background-color: #e0e0e0;*/
  margin: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  transition: .2s ease background-color;
  background-color: #fff;
}

.shape-item > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main__tab .shape-item svg,  .shape-item svg {
  width: 80px;
  height: 80px;
  scale: 0.8;
  stroke: none;
}

.main__tab .shape-item:hover {
  background-color: #2C476C;
}

.main__tab .shape-item:hover svg path, .main__tab .shape-item:hover svg polygon, .main__tab .shape-item:hover svg ellipse, .main__tab .shape-item:hover svg rect[stroke='#95d4f3'] {
  fill: white;
}

.shape-marker--root {
  width: 100%;
  height: 100%;
}

@media (min-width: 811px) and (max-width: 1921px) {
  .shape_selector--container {
    width: 720px;
  }
  .shape_selector--sidebar {
    width: 250px;
  }

  .shape_selector--sidebar-item {
    font-size: 20px;
  }

  .shape-item {
    width: 90px;
    height: 90px;
  }

  .shape_selector--list {
    gap: 10px;
  }

  .main__tab .shape-item svg,  .shape-item svg {
    width: 70px;
    height: 70px;
    stroke: none;
  }
}


@media (max-width: 810px) {
  .shape_selector--sidebar {
    display: none;
  }

  .shape_selector--container {
    width: 300px;
    max-width: 100%;
    padding-top: 4px;
  }

  .shape_selector--body {
    padding-inline: 20px;
  }

  .shape-item {
    width: 44px;
    height: 44px;
  }

  .shape_selector--list {
    gap: 15px;
  }

  .main__tab .shape-item svg,  .shape-item svg {
    width: 42px;
    height: 42px;
    max-height: 42px;
    stroke: none;
  }

  .shape_selector--category-section h2 {
    font-size: 17px;
  }
}


/* EDITOR */

.shape_tool_editor--container {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.shape_tool_editor--container--active {
  display: block;
}

.shape-marker {
  transition: .2s ease-in-out width, .2s ease-in-out height;
  will-change: auto;
  z-index: 1002 !important;
}

.shape-marker--container {
  /*transition: .1s scale;*/
  width: 100%;
  height: 100%;
  position: relative;
  /*position: absolute;*/
  /*top:50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
  /*will-change: auto;*/
  /*transition: width 0.2s ease-in-out, height 0.2s ease-in-out;*/
}

.shape-marker--target {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  /*translate: -50% -50%;*/
  /*transition: all 0.2s ease-in-out;*/
  will-change: auto;
  transform: translate(-50%, -50%) scale(1,1) rotate(0deg);
}

.shape-marker--target svg {
  width: 100%;
  height: 100%;
}

.shape-marker--root svg * {
  /*vector-effect: non-scaling-stroke;*/
}

.line-arrow--target svg * {
  /*vector-effect: non-scaling-stroke;*/
}

.shape-target__arrow {
  background-color: transparent;
  position: relative;
}

.shape-arrow_line {
  width: calc(100% - 70px);
  height: 20%;
  position: absolute;
  background-color: #000;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}

.shape-arrow {
  width: 100px;
  height: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 2;
  /*aspect-ratio: 2/6;*/
  background-color: transparent;
}

.shape-arrow svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.group-shape--target .moveable-control-box  .moveable-control.moveable-clip-control, .group-shape--target .moveable-line .moveable-clip-line {
  display: none;
}

.shape-marker--svg {

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


/*TEMP*/

#mapSlider, [data-is-windows="true"], [data-is-windows="false"]{
  z-index: 960 !important;
}

.map {
  z-index: 0;
}

.shape-marker--ghost {
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: auto;
  /*z-index: 1;*/
  transform-origin: center;
}

/*TEMP*/

@media screen and (max-width: 1024px) {
  div.header_right_mobile:has(.active){
    min-width:210px;
  }

  div.mobile_menu_buttons {
    padding-left: 10px !important;
  }

}

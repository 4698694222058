
.loader3 {
    width:50px;
    height:50px;
    display:inline-block;
    padding:0px;
    text-align:left;
}
.loader3 span {
    position:absolute;
    display:inline-block;
    width:50px;
    height:50px;
    border-radius:100%;
    background: rgb(20 27 55);
    -webkit-animation:loader3 1.5s linear infinite;
    animation:loader3 1.5s linear infinite;
}
.loader3 span:last-child {
    animation-delay:-0.9s;
    -webkit-animation-delay:-0.9s;
}
@keyframes loader3 {
    0% {transform: scale(0, 0);opacity:0.8;}
    100% {transform: scale(1, 1);opacity:0;}
}
@-webkit-keyframes loader3 {
    0% {-webkit-transform: scale(0, 0);opacity:0.8;}
    100% {-webkit-transform: scale(1, 1);opacity:0;}
}

.toolboxContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #2C476C4A;
    padding: 5px 15px;
    position: fixed;
    bottom: 206px;
    right: 15px;
    border-radius: 10px;
    height: 47px;
}

.toolboxItem {
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 15px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.toolboxItem.toolboxScaleItem {
    width: 100px;
    border-bottom: 2px solid #fff;
    border-top: none;
    padding: 6px 19px 6px 70px;
    position: relative;
}
.toolboxItem.toolboxScaleItem span{
    display: flex;
    flex-flow: nowrap;
    white-space: nowrap;
}
.toolboxItem.toolboxScaleItem:after, .toolboxItem.toolboxScaleItem:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #fff;
    bottom: 0;
}

.toolboxItem.toolboxScaleItem:before {
    left: 0;
}

.toolboxItem.toolboxScaleItem:after {
    right: 0;
}



@media (min-width: 1401px) and (max-width: 2560px) {
    .toolboxContainer {
        height: 33px;
        padding: 3px 12px;
    }

    .toolboxItem {
        font-size: 14px;
        margin: 0 5px;
        text-shadow: 0 0 1px #222;
    }

    .toolboxItem.toolboxScaleItem {
        padding: 2px 12px 2px 40px;
    }
}


@media screen and (max-width: 1400px) {
    .toolboxContainer {
        height: 32px;
        padding: 3px 10px;
    }

    .toolboxItem {
        font-size: 13px;
        margin: 0 5px;
        text-shadow: 0 0 1px #222;
    }

    .toolboxItem.toolboxScaleItem {
        padding: 1px 8px 1px 30px;
    }
}

.slide-save-content {
    display: flex;
    width: 450px;
    height: 120px;
    border-radius: 10px;
}

.slide-save-container {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
}

.slide-title p {
    color: #6E6B7B;
    font-family: Mardoto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.slide_zone {
    margin-top: 15px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.slide_buttons {
    width: 48%;
    padding: 4px 10px;
    border-radius: 5px;
    font-family: Mardoto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.slide_cancel {
    background: #EA5455;
    color: #fff;
}

.slide_submit {
    background: #24B263;
    color: #fff
}

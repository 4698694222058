.container-presentation {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    border-radius: 12px;
    background: #FFF;
    width: 27%;
    left: 105px;
    top: max(calc(100vh/216.2),5px);
    min-width: 360px;
    max-width: 450px;
    align-self: flex-start;
    padding: 8px;
}

.header__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.header__first__section {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header__first__section button{
    width: 36px;
    height: 36px;
}
.header__first__section [data-is-loading="true"]{
    margin-right: 3px;
    margin-bottom: 5px;
}
.header__second__section{
    display: flex;
}
.header__first__section button:disabled{
    opacity: 0.6;
}
.header__first__section p,
.header__first__section input{
    color: #6E6B7B;
    font-family: Mardoto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-left: 10px;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 3px;
}

.edit_presentation_title_input {
    border: 1px solid #6E6B7B;
    padding: 0 2px !important;
    border-radius: 5px;
}

.header__second__section button {
    width: 16px;
    height: 16px;
    margin-left: 7px;
    margin-top: 8px;
  }

@media (max-width: 1024px) {
    .container {
        top: 25px
    }
}

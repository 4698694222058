.article-mode-header{
    position: relative;
    top: 30px;
    left: -5px;
    width: 100%;
    height: 0px;
    z-index: 1;
    display: flex;
    justify-content: right;
    align-items: center;
}
.article-mode-header__full-screen-btn-box{
    position: relative;
    display: block;
    aspect-ratio: 1/1;
    height: calc(60px * 0.7);
    background-color: #fff;
    margin-bottom: 5px;
    border-radius: 10px;
    box-shadow: 0px 7px 26px rgb(44 71 108 / 10%);
    margin-right: calc(100vw / 426.66);
}
.article-mode-header__full-screen-btn{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.article-mode-header__full-screen-btn svg{
    width: 15px;
    height: 15px;
    stroke: var(--main-color);
}
.card-body__search.date-search{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    z-index: 1;
}
.card-body__search.date-search input{
    width: 300px;
    padding: 5px 10px;
    border: 1px solid #D8D8D8;
    color: white;
    border-radius: 5px;
}
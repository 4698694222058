.screenShot_notification_popup-box{
    width: 450px;
    background-color: rgba(255, 255, 255, 1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    position: relative;
}
.screenShot_notification_popup-box h3{
    display: flex;
    align-items: center;
    gap: 10px;
}
.screenShot_notification_popup-box h3 span{
    height: 30px;
}
.screenShot_notification_popup-box .screenShot_notification-description p {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 1px;
    margin: 0;
    font-family: Mardoto;
    text-align: center;
}
#screenShot_notification_icon{
    background-color: #fff;
}
.screenShot_notification_popup-box-close-btn{
    position: absolute;
    top: 10px;
    right: 15px;
    height: 20px;
}
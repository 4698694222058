.header__burger {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 60px;
  height: 100%;
  font-size: 28px;
  z-index: 4;
  height: 60px;
  margin-top: max(calc(100vh/216.2),5px);
  margin-left: max(calc(100vw/384),5px);
  border-radius: 10px;
  box-shadow: 0px 7px 26px rgba(44, 71, 108, 0.1);
  font-weight: 600;
}
.account .header__burger{
  margin-top: max(calc(100vh/56),5px);
  margin-left: max(calc(100vw/100),5px);
}
.header__burger.active {
  left: 325px;
  background-color: var(--main-color);
  font-size: 0;
}
.header__burger.active::before,
.header__burger.active::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20px;
  height: 2px;
  background-color: #fff;
}
.header__burger.active::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.header__burger.active::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.header__nav {
  position: fixed;
  left: -100%;
  top: 0;
  z-index: 14;
  height: 100%;
  width: 325px;
  background-color: var(--main-color);
  padding: 0 20px;
}

.header__nav.active {
  left: 0;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
  .account  .header__burger  {
    transform: scale(0.8);
    margin: 0 0 0 0 ;
  }
  .account  .header__nav{
    width: 260px;
  }
  .account  .header__burger.active{
    left: 260px;
  }
}

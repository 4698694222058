.firstText {
    color: #BCB4B9;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
}

.resendText {
    border-bottom: 1px solid transparent;
    text-transform: uppercase;
    color: #BCB4B9;
    font-weight: 500;
    font-size: 16px;
    margin-left: 10px;
}

.resend-container {
    display: flex;
    align-items: center;
}
.resend-container button {
    text-transform: uppercase;
}

.resendTextEnabled {
    border-bottom: 1px solid #FFFFFF;
    color: #fff;
    font-weight: 400;
    margin-left: 10px;
}

.countDown {
    margin-left: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
}

.black {
    color: #000
}
@media (max-width: 512px) {
    .black{
        border-left: 0!important;
    }
    .phoneContainer +  .black{
        border-left: 1px solid #CACACA!important;
    }
}

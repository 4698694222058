article.custom-card-1__card-body.card-body:has(div.bookmark-like-box[data-liked="false"])
~ article.custom-card-1__card-body.card-body {
    animation: leftTransition forwards 0.7s ease-in-out 1;
}
article.custom-card-1__card-body.card-body:has(div.bookmark-like-box[data-liked="false"])
~ article.custom-card-1__card-body.card-body:nth-child(6) {
    animation: rightTransition forwards 0.7s ease-in-out 1;
}
article.custom-card-1__card-body.card-body:has(div.bookmark-like-box[data-liked="false"]) {
    animation: fallAway forwards 0.8s ease-in-out 1;
}

@keyframes fallAway {
    0% {
        scale: 1;
        top: 0;
        opacity: 1;
    }
    25% {
        scale: 0.5;
    }
    100% {
        scale: 0;
        top: -300px;
        opacity: 0;
    }
}
@keyframes leftTransition {
    0% {
        translate: 0%;
    }
    99% {
        translate: calc(-100% - 20px);
    }
    100% {
        translate: 0 0;
    }
}
@keyframes rightTransition {
    0% {
        translate: 0 0;
    }
    99% {
        translate: calc(400% + 80px) calc(-100% - 20px);
    }
    100% {
        translate: 0 0;
    }
}



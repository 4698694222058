.card-body__choose-slide-form {
    background: #fff;
    padding: 10px 20px 0 15px;
    border-radius: 8px;
    width: 40vw;
    display: flex;
    flex-direction: column;
}

.choose__header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.choose__header__container div {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.choose__header__container button {
    width: 15px;
    height: 15px;
}

.first__section h5 {
    text-transform: none;
    margin-left: 5px;
}

.card-body__edit-slide-title-form {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
}

.search__container {
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    width: 100%;
    padding: 5px 10px;
    color: #314769;
    font-family: Mardoto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin: 20px 0;
}
.search__container input {
    width: 100%;
}

.cards__container {
    display: flex;
    flex-wrap: wrap;
    height: 50vh!important;
    overflow-y: scroll!important;
    width: calc(40vw - 15px);
    margin: 10px -40px 0 0;
    align-self: center;
}

.cards__container::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #314769;
}

.cards__container::-webkit-scrollbar {
    width: 5px;
}

.bottom__container {
    width: 40vw;
    height: 45px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #EAEAEA;
    background: #FFF;
    box-shadow: 0px -2px 12px 0px rgba(44, 71, 108, 0.12);
    align-self: center;
    border-radius: 0px 0px 20px 20px;
    margin: 0 -2px 0 2px
}

.account-delete-modal-box{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    min-width: 700px;
    height: 400px;
    border-radius: 5px;
    gap: 30px;
}
.account-delete-modal-box h3{
    font-family: Noto Sans Armenian;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: left;

}
.account-delete-modal-box p{
    font-family: Noto Sans Armenian;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
}
.account-delete-modal-box .popup-button-container{
    margin: 0;
}
.account-delete-modal-box  .user-form-btn{
    width: 225px;
    height: 40px;
}
@media (max-width: 1024px) {
    .account-delete-modal-box {
        min-width: 75%;
    }
    .account-delete-modal-box p{
        text-align: center;
    }
}
@media (max-width: 512px) {
    .account-delete-modal-box {
        min-width: 100%;
    }
    .account-delete-modal-box .user-form-btn {
        width: 126px;
        height: 32px;
    }
}
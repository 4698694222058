.check-text {
    font-size: 26px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    letter-spacing: 2px;
    margin-top: 40px;
}
/*update 16/11/23 */
@media (max-width: 512px) {
    .check-text,.check-text:nth-child(2) {
        font-size: 16px!important;
    }
}
/*update 16/11/23 end*/
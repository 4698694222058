.slide-remove-content {
    display: flex;
    width: 450px;
    height: 150px;
    border-radius: 10px;
}

.slide-delete-container {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
}

.slide-title h4 {
    text-align: center;
    color: #6E6B7B;
    font-family: Mardoto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    text-transform: uppercase;
}
.slide-title p {
    color: #6E6B7B;
    font-family: Mardoto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.slide_zone {
    margin-top: 15px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.slide_buttons {
    width: 48%;
    padding: 4px 10px;
    border-radius: 5px;
    font-family: Mardoto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.slide_remove {
    background: #EA5455;
    color: #fff;
}

.slide__cancel {
    background: #F7F7F7;
    color: #6E6B7B
}

.language-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    backdrop-filter: blur(5px); /* Apply blur effect */
}

.language-popup {
    width: 400px;
    height: 200px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    padding: 20px;
    transition: background 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(10px); /* Extra blur inside popup */

}

.language-popup.popup-animation {
    background: transparent;
    box-shadow: none;
    backdrop-filter: none;
}

.language-popup .image-options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.language-popup .image-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    transition: 0.3s;
}
.language-popup .image-circle p{
    transition: 0.3s;
}
.language-popup .image-circle:hover p{
    opacity: 0;
}
.language-popup .image-circle:hover{
    scale: 1.1;
    translate: 0 20px;
}
.language-popup .selected-language p{
    display: none;
}
.language-popup .image-circle img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.language-popup .selected-language-to-right {
    animation: rotateMoveCenterToRight 1.5s ease forwards, hideScaleAnimation 0.5s ease forwards;
    animation-delay: 0s, 1s; /* Delay the second animation to start after the first one finishes */
}
.language-popup .selected-language-to-left {
    animation: rotateMoveCenterToLeft 1.5s ease forwards, hideScaleAnimation 0.5s ease forwards;
    animation-delay: 0s, 1s; /* Delay the second animation to start after the first one finishes */
}

@keyframes rotateMoveCenterToRight {
    0% {
        rotate: 0deg;
        translate: 0 0;
    }
    70% {
        rotate: 360deg;
        translate: 100% 0;
    }
    100% {
        rotate: 360deg;
        translate: 100% 0;
    }
}
@keyframes rotateMoveCenterToLeft {
    0% {
        rotate: 0deg;
        translate: 0 0;
    }
    70% {
        rotate: -360deg;
        translate: -100% 0;
    }
    100% {
        rotate: -360deg;
        translate: -100% 0;
    }
}
@keyframes hideScaleAnimation {
    0% {
        scale: 1;
        rotate: 360deg;
        opacity: 1;
    }
    100% {
        scale: 0;
        rotate: 360deg;
        opacity: 0;
    }
}

.disappear {
    opacity: 0;
    transition: opacity 0.5s ease;
}

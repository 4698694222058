.card-body__presentation-warning {
    background: #F5F5F5;
    border-radius: 11px;
    width: 500px;
    height: 250px;
}
.card-body__presentation-warning>article{
    display: grid;
    grid-template-rows: 50px 1fr 40px!important;
}
.card-body__dynamic-close-btn-box{
    height: 20px;
}
.card-body__dynamic-close-btn-box svg{
    height: 100%;
    width: 100%;
}
.dynamic-screen-shot-warning-content__header h4{
    font-size: 16px;
}
.dynamic-screen-shot-warning-content__footer{
    display: flex;
    gap: 15px;
}
.dynamic-screen-shot-warning-content__body{
    text-align: center;
}
.dynamic-screen-shot-warning-content__footer-btn{
    display: block;
    width: 100%;
    height: 30px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
}
.dynamic-screen-shot-warning-content__footer-cancel-btn{
    background-color: #e71313;
}
.dynamic-screen-shot-warning-content__footer-success-btn{
    background-color: #42D3B0;

}
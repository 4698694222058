.br-tooltip {
    position: relative;
    display: inline-block;
    white-space: pre-wrap;
    height: 20px;
}

.br-tooltip.variant-italic {
    font-style: italic;
    font-size: 12px;
    line-height: 23px;
}

/* Tooltip text */
.br-tooltip .br-tooltiptext {
    font-style: normal;
    font-size: 16px;
    visibility: hidden;
    width: auto;
    background-color: #e4e4e4;
    color: #30466C;
    /*font-weight: 600;*/
    text-align: center;
    padding: 7px 17px;
    border-radius: 15px;
    position: absolute;
    z-index: 1;
    top: -42px;
    left: -10px;
    opacity: 0;
    transition: all 0.2s ease;
    transform: translateY(-100%);
    text-transform: lowercase;
}

.br-tooltip .br-tooltiptext::before {
    content: '';
    position: absolute;
    display: block;
    width: 0px;
    left: 9%;
    bottom: 0;
    border: 7px solid transparent;
    border-bottom: 0;
    border-top: 7px solid #e4e4e4;
    transform: translate(0, calc(100%));
}

/* Show the tooltip text when you mouse over the tooltip container */
.br-tooltip:hover .br-tooltiptext {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
}
.hidden {
    display: none;
}
[data-is-loading="true"]{
    z-index: 1001!important;
    contain: content;
}
[data-is-loading="true"]:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    width: 100%;
    height: 100%;
    z-index: 100;
}
[data-is-loading="true"]:after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
    background: #eee;
    z-index: 1000!important;
    background: linear-gradient(110deg, var(--loader-color) 8%, var(--loader-color-2) 16%, var(--loader-color) 24%);
    border-radius: 5px;
    background-size: 200% 100%;
    -webkit-animation: 1.7s shine linear infinite;
    animation: 1.7s shine linear infinite;
}
[data-is-loading="true"].circle-load:before{
    border-radius: 50%;
}
[data-is-loading="true"].circle-load:after{
    border-radius: 50%;
}
:disabled{
    opacity: 0.7;
    cursor: "not-allowed";
}
@-webkit-keyframes shine {
    to {
        background-position-x: -200%;
    }
}
@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

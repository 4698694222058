.acc-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: 20px 40px;
    z-index: -1;
}
.upper {
    text-transform: uppercase;
    padding-right: 5px;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .acc-footer  {
        font-size: 12px;
    }
}
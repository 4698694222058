:root{
    --color-icon-additional:#ccc;
    --color-button-floating:#fff;
    --color-red:red;
    --dot-color:#2C476C;
    --color-white: #fff;
    --shadow-y:2px;
    --shadow-blur:4px;
    --shadow-color:rgba(0,0,0,0.2);
    --color-text-primary-variant:#4d4d4d;
}
.map-controls-view__control-group {
    pointer-events: auto;
}
.map-controls-view__control-group.hidden {
    visibility: hidden;
}
.map-controls-view__tilt-rotate-control {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /*margin-top: 20px;*/
    height: inherit;
}
.map-tilt-rotate-control {
    --dot-color: var(--color-icon-additional);
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transform: translateZ(0);
}

.map-tilt-rotate-control__ring {
    position: relative;
    /* width: calc(56px * 0.7);
    height: calc(56px * 0.7); */
    width: 38px;
    height: 38px;
    box-sizing: border-box;
    border: calc(10px  * 0.7) solid var(--color-button-floating);
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
    --dot-color: #2C476C;
    display: inline-block;
}
.map-tilt-rotate-control__ring[data-animate="true"] {
    transition: .5s;
}
.map-tilt-rotate-control__ring:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: none;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 4px solid var(--color-red);
    left: calc(50% - 2px);
    top: -6px;
    transition: border-bottom-color .1s;
}
.map-tilt-rotate-control__ring:after {
    content: "";
    position: absolute;
    width: calc(4px * 0.7);
    height: calc(4px * 0.7);
    border-radius: 50%;
    top: calc(50% - 1px);
    left: calc(50% - 1px);
    box-shadow: 15px 0 0 0 var(--dot-color),0 15px 0 0 var(--dot-color),-16px 0 0 0 var(--dot-color);
    transition: box-shadow .1s;
}
.map-tilt-rotate-control__tilt-wrapper {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
}
.map-tilt-rotate-control__tilt-ring{
    border-radius: 50%;
}
.map-tilt-rotate-control__tilt {
   position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: var(--color-button-floating);
    transition: background-color .4s,color .4s,transform .001ms;
    cursor: pointer;
    font-size: 16px;
    color: var(--color-text-primary-variant);
    text-align: center;
}
.map-tilt-rotate-control__tilt._tilted {
    color: #2C476C;
    font-family: Lato;
    font-size: calc(16px * 0.7);
    background-color: var(--color-white);
    cursor: pointer;
    font-weight: 700;

}
.map-tilt-rotate-control__tilt._tilted[data-animate="true"]{
    transition: .3s;

}
.map-tilt-rotate-control__tilt._tilted[data-get-start="false"]{
    opacity: .7;
}
.map-tilt-rotate-control:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    /* width: calc(56px * 0.7);
    height: calc(56px * 0.7); */
    width: 38px;
    height: 38px;
    box-sizing: border-box;
    border: calc(10px * 0.7) solid transparent;
    box-shadow: inset 0 var(--shadow-y) var(--shadow-blur) 0 var(--shadow-color),0 var(--shadow-y) var(--shadow-blur) 0 var(--shadow-color);
    border-radius: 50%;
}
.map-tilt-rotate-control__ring:hover {
    --dot-color: #999;
}


.map-tilt-rotate-control{
width: calc(60px * 0.7);
height: calc(60px * 0.7);
}

 .map-tilt-rotate-control__tilt-ring{
width: calc(60px * 0.7);
height: calc(60px * 0.7);
display: flex;
justify-content: center;
align-items: center;
}
.map-tilt-rotate-control__tilt._tilted[data-disabled="true"]{
    opacity: 0.6;
}
@media screen and  (min-width: 1801px) {
  .map-tilt-rotate-control{
    width: 60px;
    height: 60px;
  }
  .map-tilt-rotate-control__tilt-ring {
    width: 60px;
    height: 60px;
  }

  .map-tilt-rotate-control__ring {
    border: 10px solid var(--color-button-floating);
    width: 56px;
    height: 56px;
  }

  .map-tilt-rotate-control__ring:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: none;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 6px solid var(--color-red);
    left: calc(50% - 3px);
    top: -9px;
    transition: border-bottom-color .1s;
  }

  .map-tilt-rotate-control__ring:after {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    top: calc(50% - 2px);
    left: calc(50% - 2px);
    box-shadow: 22px 0 0 0 var(--dot-color),0 22px 0 0 var(--dot-color),-22px 0 0 0 var(--dot-color);
    transition: box-shadow .1s;
  }

  .map-tilt-rotate-control__tilt._tilted {
    font-size: 16px;
  }

  .map-tilt-rotate-control:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 56px;
    height: 56px;
    box-sizing: border-box;
    border: 10px solid transparent;
    box-shadow: inset 0 var(--shadow-y) var(--shadow-blur) 0 var(--shadow-color),0 var(--shadow-y) var(--shadow-blur) 0 var(--shadow-color);
    border-radius: 50%;
  }

  .map-tilt-rotate-control__tilt {
     width: 100%;
    height: 100%;
 }
  .map-tilt-rotate-control__tilt-wrapper {
    position: absolute;
    width: 28px;
    height: 28px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
  }
}

@media screen and  (max-width: 315px) {
  .map-tilt-rotate-control{
    width: 60px;
    height: 60px;
  }
  .map-tilt-rotate-control__tilt-ring {
    width: 60px;
    height: 60px;
  }

  .map-tilt-rotate-control__ring {
    border: 10px solid var(--color-button-floating);
    width: 56px;
    height: 56px;
  }

  .map-tilt-rotate-control__ring:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: none;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 6px solid var(--color-red);
    left: calc(50% - 3px);
    top: -9px;
    transition: border-bottom-color .1s;
  }

  .map-tilt-rotate-control__ring:after {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    top: calc(50% - 2px);
    left: calc(50% - 2px);
    box-shadow: 22px 0 0 0 var(--dot-color),0 22px 0 0 var(--dot-color),-22px 0 0 0 var(--dot-color);
    transition: box-shadow .1s;
  }

  .map-tilt-rotate-control__tilt._tilted {
    font-size: 16px;
  }

  .map-tilt-rotate-control:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 56px;
    height: 56px;
    box-sizing: border-box;
    border: 10px solid transparent;
    box-shadow: inset 0 var(--shadow-y) var(--shadow-blur) 0 var(--shadow-color),0 var(--shadow-y) var(--shadow-blur) 0 var(--shadow-color);
    border-radius: 50%;
  }

  .map-tilt-rotate-control__tilt {
     width: 100%;
    height: 100%;
 }
  .map-tilt-rotate-control__tilt-wrapper {
    position: absolute;
    width: 28px;
    height: 28px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
  }
}
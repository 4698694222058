.container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.check-text {
    font-size: 26px !important;
    text-transform: unset;
    text-align: center;
    color: #fff;
}
.check-text:nth-child(2) {
    font-size: 26px !important;
    text-transform: unset;
    text-align: center;
    color: #fff;
    font-weight: bold;
}

.changePhone {
    border-bottom: 1px solid #FFFFFF;
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
}
.confirme-code-box{
    width: 450px;
}
.registrate-btn{
    width: 450px;
}

@media (max-width: 512px) {
    .check-text,.check-text:nth-child(2) {
        font-size: 16px!important;
    }
    .container {
        width: 100%;
        padding: 0 15px;
    }
    .confirme-code-box{
        width: 100%;
    }
    .registrate-btn{
        width: 100%;
    }
    .resend-container{
        flex-direction: column;
        gap: 5px;
    }
}
/*update 16/11/23 end*/

.screenShot_notification-box{
    display: flex;
    height: 60px;
}
.screenShot_notification-box .screenShot_notification-description {
    width: 0px;
    height: 100%;
    transition: 0.4s;
    overflow: hidden;
    font-family: Mardoto;
    display: flex;
    align-items: center;
}
.screenShot_notification-box .screenShot_notification-description p {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 1px;
    margin: 0;
    opacity: 0;
    transition-delay: 0.7s;
    transition-duration: 0.2s;
}
.screenShot_notification-box.active .screenShot_notification-description{
    height: 100%;
    width: 500px;
    padding: 10px;
    background-color: white;
    border-radius: 10px 0px 0px 10px!important;
}
.screenShot_notification-box.active .screenShot_notification-description p {
    opacity: 1;
}
.screenShot_notification-box #screenShot_notification_icon{
    transition-duration: 0.6s;
}
.screenShot_notification-box.active #screenShot_notification_icon{
    border-radius: 0px 10px 10px 0px!important;
}
#screenShot_notification_icon{
    background-color: #fff;
}
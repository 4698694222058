#svgsvg{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #7396a6;
    z-index: 99;
    /*opacity: 0.4;*/
}
#world-map {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    height: 90% !important;
    aspect-ratio: 16/9;
    stroke: url("#gradient");
    stroke-width: 1px; /* Stroke width */
    fill: none; /* No fill */
    stroke-dasharray: 1000;
    stroke-dashoffset: 500;
    animation: draw-world-map 15s linear infinite;
    opacity: 0.8;
}
@keyframes draw-world-map {
    25% {
        stroke-dashoffset: 0;
        opacity: 1;
    }
    50% {
        stroke-dashoffset: 1000;
        opacity: 0.8;
    }
    to {
        stroke-dashoffset: 0;
        opacity: 1;
    }
}

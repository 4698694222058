.error-msg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -300px;
    padding: 10px;
    background-color: #eb572c;
    color: #fff;
    font-size: 14px;
    max-width: 270px;
    display: none;
}


article.custom-card-1__card-body.card-body.screenshot_item_wrapper_remove_animation~article.custom-card-1__card-body.card-body {
    animation: leftTransition forwards 0.7s ease-in-out 1;
}

article.custom-card-1__card-body.card-body.screenshot_item_wrapper_remove_animation~article.custom-card-1__card-body.card-body:nth-child(6) {
    animation: rightTransition forwards 0.7s ease-in-out 1;
}

article.custom-card-1__card-body.card-body.screenshot_item_wrapper_remove_animation {
    animation: fallAway forwards 0.8s ease-in-out 1;
}

.card-body__edit-slide-title-form {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
}

.card-body__edit-slide-title-form h5 {
    text-transform: uppercase;
    text-align: center;
    color: #6E6B7B;
    font-family: Mardoto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
}

.card-body__edit-slide-title-form p {
    color: rgba(110, 107, 123, 0.58);
    font-family: Mardoto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.edit-slide-title-form__btn-box{
    position: relative;
    margin-bottom: 12px;
    width: 480px;
}
.edit-slide-title-form__btn-box>div{
    width: 450px;
    padding: 0;
    height: 40px;
    border-radius: 5px;
}

.fixed-size-textarea {
    resize: none;
    width: 100% !important;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    padding: 6px 10px;
    color: #6E6B7B;
    font-family: Mardoto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.fixed-size-textarea:hover, .edit__slide__title__input:hover{
    border: 1px solid black
}

.fixed-size-textarea:focus, .edit__slide__title__input:focus {
    border: 1px solid #2C476C
}

.edit__slide__button {
    background-color: #F7F7F7;
    color: #6E6B7B;
    font-family: Mardoto;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    boxShadow: none;
    width: 50%;
}

.edit__slide__title__input {
    border: 1px solid #D8D8D8;
    width: 100%;
    border-radius: 5px;
    padding: 6px 10px;
    color: #6E6B7B;
    font-family: Mardoto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

@keyframes fallAway {
    0% {
        scale: 1;
        top: 0;
        opacity: 1;
    }

    25% {
        scale: 0.5;
    }

    100% {
        scale: 0;
        top: -300px;
        opacity: 0;
    }
}

@keyframes leftTransition {
    0% {
        translate: 0%;
    }

    99% {
        translate: calc(-100% - 20px);
    }

    100% {
        translate: 0 0;
    }
}

@keyframes rightTransition {
    0% {
        translate: 0 0;
    }

    99% {
        translate: calc(400% + 80px) calc(-100% - 20px);
    }

    100% {
        translate: 0 0;
    }
}

.mapbox-popup-draw-polygon-container {
    max-width: 130px;
    width: 130px;
    height: 35px;
    left: 75px;
    top: -10px;
}

.mapbox-popup-draw-line-container {
    height: 35px;
}

.mapbox-popup-draw-polygon-container > .mapboxgl-popup-content  {
    border-radius: 10px;
    height: 35px;
}

.mapbox-popup-draw-polygon-container > .mapboxgl-popup-tip {
    display: none !important;
}

.draw-polygons-popup-container {
    width: 130px;
    min-height: 35px;
    height: 35px;
    background-color: #2C476C;
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
    border-radius: 10px;
    color: #fff;
    position: absolute;
    box-shadow: 0 4px 24px 0 #292E3233;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
}

.draw-polygons-popup-container_color-picker {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: calc(30% + 10px);
    height: 100%;
    border-right: 2px solid #FFFFFF1F;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
}

.draw-polygons-popup-container_actions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: calc(70% - 10px);
    border-radius: 0 5px 5px 0;
    padding: 5px;
}

.draw-polygons-popup-container_actions > * {
    margin: 0 3px;
    cursor: pointer;
}

.draw-lines-popup-container_color-picker,
.draw-lines-popup-container_weight-picker {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: calc(30% + 10px);
    height: 100%;
    border-right: 2px solid #FFFFFF1F;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
}

.draw-polygons-popup-container > .color-and-opacity-picker-popup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: absolute;
    width: 320px;
    padding: 10px;
    height: 110px;
    bottom: calc(100% + 5px);
    background-color: #2c476c;
    border-radius: 10px;
    box-shadow: 0px 4px 24px 0px #292E3233;
}

.draw-polygons-popup-container > .color-and-opacity-picker-popup > .colors-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.draw-polygons-popup-container > .color-and-opacity-picker-popup > .fill-stroke-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.draw-polygons-popup-container > .color-and-opacity-picker-popup > .fill-stroke-buttons > .fill-stroke-button {
    width: 48%;
    height: 27px;
    font-size: 1rem;
    border-radius: 5px;
    background-color: #fff;
    color: #5b5b5b;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.draw-polygons-popup-container > .color-and-opacity-picker-popup > .fill-stroke-buttons > .fill-stroke-button.active {
    background-color: #24B263;
    color: #fff;
}

.draw-polygons-popup-container_color-picker > .current-picked-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.draw-polygons-popup-container_color-picker > .current-picked-color > svg {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.draw-polygons-popup-container > .color-and-opacity-picker-popup > .colors-row > .polygon_tool_color_data_item > .available-color {
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 50%;
}

.opacity-selector {
    width: 100%;
}

.opacity-selector > .range-container {
    position: relative;
    display: flex;
    height: 22px;
    width: 100%; /* Adjust width as needed */
    background-color: #fff;
    border-radius: 20px;
}

.opacity-selector > .range-container > .background, .opacity-selector > .range-container > .checkerboard {
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none; /* Makes sure the div does not interfere with the input */
    cursor: pointer;
}

.opacity-selector > .range-container > .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}

.opacity-selector > .range-container > .checkerboard {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image:
            linear-gradient(45deg, #ccc 25%, transparent 25%),
            linear-gradient(-45deg, #ccc 25%, transparent 25%),
            linear-gradient(45deg, transparent 75%, #ccc 75%),
            linear-gradient(-45deg, transparent 75%, #ccc 75%);
    background-size: 14px 14px; /* Size of each square */
    background-position: 0 0, 0 7px, 7px -7px, -7px 0px;
    z-index: 1;
}

.custom-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%; /* Full width */
    height: 100%; /* Adjust height as needed */
    background: transparent; /* Hide the track */
    position: relative;
    z-index: 3;
    cursor: pointer;
}

/* Webkit browsers (Chrome, Safari) */
.custom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 14px; /* Thumb width */
    height: 14px; /* Thumb height */
    background: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12.285 17.57C15.2038 17.57 17.57 15.2038 17.57 12.285C17.57 9.36617 15.2038 7 12.285 7C9.36617 7 7 9.36617 7 12.285C7 15.2038 9.36617 17.57 12.285 17.57Z" fill="white"/></svg>') no-repeat center;
    background-size: cover;
    cursor: pointer;
}

/* Firefox */
.custom-slider::-moz-range-thumb {
    width: 14px; /* Thumb width */
    height: 14px; /* Thumb height */
    background: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12.285 17.57C15.2038 17.57 17.57 15.2038 17.57 12.285C17.57 9.36617 15.2038 7 12.285 7C9.36617 7 7 9.36617 7 12.285C7 15.2038 9.36617 17.57 12.285 17.57Z" fill="white"/></svg>') no-repeat center;
    background-size: cover;
    cursor: pointer;
}

/* IE and Edge */
.custom-slider::-ms-thumb {
    width: 14px; /* Thumb width */
    height: 14px; /* Thumb height */
    background: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12.285 17.57C15.2038 17.57 17.57 15.2038 17.57 12.285C17.57 9.36617 15.2038 7 12.285 7C9.36617 7 7 9.36617 7 12.285C7 15.2038 9.36617 17.57 12.285 17.57Z" fill="white"/></svg>') no-repeat center;
    background-size: cover;
    cursor: pointer;
}

.polygon_draw_tools_color_item_container {
    padding: 10px 12px;
    background: #fff;
    position: absolute;
    width: 228px;
    height: 180px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 1;
    bottom: -103px;
    right: -422px;
    box-shadow: 0 4px 24px 0 #292E3233;
}


.polygon_draw_tools_color_item {
    width: 16px;
    height: 16px;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.line_draw_tools_color_item:hover {
    border: 2px solid #fff;
}

.line_draw_tools_color_item_container > span {
    width: 22px;
    height: 22px;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
    left: 89%;
    top: 98%;
    background: #fff;
}

.draw-polygons-popup-container_weight-picker > .current-line-weight {
    width: 30px;
    text-align: center;
    font-size: 14px;
}

.triangle-icon-wrapper {
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.triangle-icon-wrapper > .triangle-icon {
    width: 12px;
    height: 6px;
    transition: all 0.3s ease;
    transform: rotate(180deg);
}

.triangle-icon-wrapper > .triangle-icon.active {
    transform: rotate(0deg);
}

.dropdown-content-wrapper {
    width: 100%;
    position: relative;
}

.dropdown-content {
    position: absolute;
    background-color: #ffffff;
    width: 58px;
    top: 35px;
    max-height: 100px;
    overflow-y: auto;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 0 0 7px 10px;
}

.dropdown-content span {
    color: #6E6B7B;
    padding: 2px 10px;
    text-decoration: none;
    display: block;
}

.dropdown-content span:hover {
    background-color: #f1f1f1;
}

.dropdown-content span.selected {
    font-weight: bold;
}

.dropdown-content::-webkit-resizer {
    display: none;
}

.dropdown-content::-webkit-scrollbar {
    width: 7px;
    padding: 0px 2px;
}

.dropdown-content::-webkit-scrollbar-track {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
}

.dropdown-content::-webkit-scrollbar-thumb {
    width: 7px !important;
    background-color: #2c476c;
    outline: none;
    border-radius: 5px;
}

.mapbox-hover-popup-draw-line-container {
    left: 10px;
    top: 5px;
}

.hover-popUp-container-line-string {
    position: absolute;
    right: 20px;
    bottom: -5px;
    width: max-content;
    border-radius: 15px;
    padding: 10px;
    background: #fff;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    animation: anim 0.3s;
    transition: 0.3s ease-in-out;
}

.hover-popUp-container-line-string > .hover-popUp-text {
    height: fit-content;
    font-size: 0.8rem;
    margin: 0;
}

.delete-icon-wrapper {
    width: 22px;
}

.close-icon-wrapper {
    width: 25px;
}

@keyframes anim {
    0% {
     opacity: 0;
    }
    100% {
     opacity: 1;
    }
}

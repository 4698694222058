.card-body__presentation-info-image-box.presentation-info-image-box{
    display: flex;
    width: 100%;
    flex:1;
    position: relative;
    aspect-ratio: 1;
}
.card-body__presentation-info-image-box.presentation-info-image-box:after {
    content: attr(data-count);
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-family: Noto Sans Armenian;
    font-weight: 600;
    letter-spacing: 0em;
    font-size: 12px;
}
.card-body__presentation-info-image-box-contour{
    width: 120%;
    height: 120%;
    position: absolute;
    border-right: 3px solid #EBEBEB;
    border-top: 3px solid #EBEBEB;
    border-radius: 5px 10px 5px 10px;
}
.image-box-contour-1{
    width: 100%;
    height: 100%;
    translate: 5px -5px;
}
.image-box-contour-2{
    width: 100%;
    height: 100%;
    translate: 10px -10px;

}
.presentation-image{
    border-radius: 8px;
    overflow: hidden;
}
@media screen and (min-width: 900px) and (max-width: 1800px)  {
    .card-body__presentation-info-image-box.presentation-info-image-box {
        margin-bottom: 3px;
    }
}
